import React from "react"
import { graphql } from "gatsby"
import Link from 'gatsby-link'

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import ContentContainer from "../../components/contentContainer"
import ImageTextTile from "../../components/imageTextTile"
import HeadingTile from "../../components/headingTile"
import TextTile from "../../components/textTile"
import {NewsletterTile, TicketsTile} from "../../components/svgTiles"

class Camp extends React.Component {
  render() {
    const { data } = this.props
    const node = data.allMarkdownRemark.edges[0].node
    const siteTitle = data.site.siteMetadata.title
    const infosImg = data.infoImg.edges[0].node.childImageSharp.fluid
    const programImg = data.rueckblickImg.edges[0].node.childImageSharp.fluid
    const callForPapersImg = data.callForPapersImg.edges[0].node.childImageSharp.fluid
    const sponsorsImg = data.sponsorenImg.edges[0].node.childImageSharp.fluid

    const layoutContentTakesPlace = 
      <div className={`columns is-multiline`}>

          <div className={`column is-3-desktop is-3-tablet is-12-mobile`}>
            <Link to="/camp/callforpapers">
              <ImageTextTile image={callForPapersImg} heading="Call for papers" prio={2} isBig />
            </Link>
          </div>
            <div className="column is-3-desktop is-3-tablet is-12-mobile">
              <Link to="/camp/infos">
                <ImageTextTile image={infosImg} heading="Infos" prio={2} isBig/>
              </Link>
            </div>
            <div className="column is-3-desktop is-3-tablet is-12-mobile">
              <Link to="/camp/programm">
              <ImageTextTile image={programImg} heading="Programm" prio={2} isBig/>
              </Link>
            </div>
            <div className="column is-3-desktop is-3-tablet is-12-mobile">
              <Link to="/camp/tickets">
                <TicketsTile />
              </Link>
            </div>
            <div className={`column is-6-desktop is-6-tablet is-12-mobile`}>
              <Link to="/camp/sponsoren">
                <ImageTextTile image={sponsorsImg} heading="Sponsoren" isSmall prio={2} isBig/>
              </Link>
            </div>
            <div className={`column is-6-desktop is-6-tablet is-12-mobile`}>
              <Link to="/camp/newsletter">
                <NewsletterTile isSmall />
              </Link>
            </div>
        </div>

    const layoutContentDoesntTakePlace = 
      <div className={`columns is-multiline`}>
            <div className="column is-4-desktop is-4-tablet is-12-mobile">
              <Link to="/camp/infos">
                <ImageTextTile image={infosImg} heading="Infos" prio={2} isBig/>
              </Link>
            </div>
            <div className="column is-4-desktop is-4-tablet is-12-mobile">
              <Link to="/camp/rueckblick">
              <ImageTextTile image={programImg} heading="Rückblick" prio={2} isBig/>
              </Link>
            </div>
            <div className="column is-4-desktop is-4-tablet is-12-mobile">
              <Link to="/camp/newsletter">
                <NewsletterTile />
              </Link>
            </div>
      </div>

    const layoutContent = node.frontmatter.takesPlace ? layoutContentTakesPlace : layoutContentDoesntTakePlace

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title={node.frontmatter.seoTitle || node.frontmatter.title} description={node.frontmatter.seoDescription} />
        <div className="content">
          <ContentContainer>
            {layoutContent}
            <div className="columns">
              <div className={`column is-6-desktop is-6-tablet is-12-mobile`}>
                <HeadingTile heading={node.frontmatter.title} />
              </div>
              <div className={`column is-6-desktop is-6-tablet is-12-mobile`}>
                <TextTile 
                  content={node.html} 
                />
              </div>
            </div>
          </ContentContainer>
        </div>
      </Layout>
    )
  }
}

export default Camp

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    infoImg: allFile(filter: {extension: {regex: "/(jpg)|(jpeg)|(png)/"}, absolutePath: {regex: "/assets/infos-camp/"}}) {
      edges {
        node {
          id
          absolutePath
          childImageSharp {
            fluid(maxWidth: 1800) {
              ...GatsbyImageSharpFluid
              originalName
            }
          }
        }
      }
    } 
    rueckblickImg: allFile(filter: {extension: {regex: "/(jpg)|(jpeg)|(png)/"}, absolutePath: {regex: "/assets/programm-camp/"}}) {
      edges {
        node {
          id
          absolutePath
          childImageSharp {
            fluid(maxWidth: 1800) {
              ...GatsbyImageSharpFluid
              originalName
            }
          }
        }
      }
    } 
    callForPapersImg: allFile(filter: {extension: {regex: "/(jpg)|(jpeg)|(png)/"}, absolutePath: {regex: "/assets/callforpapers/"}}) {
      edges {
        node {
          id
          absolutePath
          childImageSharp {
            fluid(maxWidth: 1800) {
              ...GatsbyImageSharpFluid
              originalName
            }
          }
        }
      }
    } 
    sponsorenImg: allFile(filter: {extension: {regex: "/(jpg)|(jpeg)|(png)/"}, absolutePath: {regex: "/assets/sponsoren/"}}) {
      edges {
        node {
          id
          absolutePath
          childImageSharp {
            fluid(maxWidth: 1800) {
              ...GatsbyImageSharpFluid
              originalName
            }
          }
        }
      }
    } 
    allMarkdownRemark(filter: {fileAbsolutePath: {regex: "/camp/index.md/"}}) {
      edges {
        node {
          fields {
            slug
          }
          html
          fileAbsolutePath
          frontmatter {
            title
            date
            takesPlace
            seoTitle
            seoDescription
          }
        }
      }
    }
    
  }
`

